import * as yup from 'yup';

export const licenseSchema = (obj: any) =>
	yup
		.object({
			id: yup.string(),
			name: yup.string(),
			owner: yup.string(),
			region: yup.string(),
			status: yup.string(),
			dateAdded: yup.number(),
			slug: yup.string(),
			language: yup.string(),
			plan: yup.string(),
			purchaseCode: yup.string(),
			orderNumber: yup.string(),
			timezone: yup.string(),
			materialNumber: yup.string(),
			catalogUid: yup.string(),
			catalogPublicId: yup.string(),
			licenseName: yup.string(),
			licenseUsage: yup.object(),
			quantity: yup.number(),
			capacity: yup.number(),
			userQuantity: yup.number(),
			userCapacity: yup.number(),
			orderType: yup.string(),
			expirationDate: yup.number(),
			userExpirationDate: yup.number(),
			isExpired: yup.boolean(),
			isManager: yup.boolean(),
			isOwner: yup.boolean(),
			managerEmails: yup.array(yup.string()),
			authMethod: yup.string()
		})
		.isValidSync(obj);

export const usersSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					dateAdded: yup.number(),
					firstName: yup.string(),
					lastName: yup.string(),
					userName: yup.string(),
					email: yup.string(),
					licensed: yup.boolean(),
					roles: yup.array(yup.string()),
					groupList: yup.array(yup.string()),
					nonMFPLicenseCapacity: yup.number(),
					nonMFPLicenseQuantity: yup.number(),
					status: yup.string()
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const pendingUsersSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					dateAdded: yup.number(),
					email: yup.string(),
					licensed: yup.boolean(),
					roles: yup.array(yup.string()),
					groupList: yup.array(yup.string()),
					nonMFPLicenseCapacity: yup.number(),
					nonMFPLicenseQuantity: yup.number(),
					status: yup.string()
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const userGroupsSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					name: yup.string(),
					defaultUser: yup.string(),
					roles: yup.array(yup.string())
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const devicesSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					serial: yup.string(),
					name: yup.string(),
					model: yup.string(),
					location: yup.string(),
					STCApp: yup.boolean(),
					stratusApp: yup.boolean(),
					licensed: yup.boolean(),
					ip: yup.string(),
					groupList: yup.array(yup.string()),
					status: yup.string()
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const deviceGroupsSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					name: yup.string()
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const inputNodesSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					userId: yup.string(),
					licenses: yup.object({
						licenses: yup.object()
					}),
					capacity: yup.number(),
					quantity: yup.number()
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
		Object.keys(obj[key]?.licenses?.licenses ?? {}).forEach((k: string | number) => {
			if (
				!yup.string().isValidSync(k) ||
				!yup
					.array(
						yup.object({
							workflows: yup.array(
								yup.object({
									workflowId: yup.string(),
									refCount: yup.number()
								})
							),
							identifier: yup.string()
						})
					)
					.isValidSync(obj[key].license.license[k])
			) {
				bool = false;
			}
		});
	});
	return bool;
};

export const rolesSchema = (obj: any) => {
	let bool = true;
	Object.keys(obj).forEach((key: string | number) => {
		if (
			!yup.string().isValidSync(key) ||
			!yup
				.object({
					id: yup.string(),
					name: yup.string(),
					permissions: yup.object({
						workflowsTab: yup.object({
							workflowsSection: yup.object({
								changeOwner: yup.boolean(),
								createGroup: yup.boolean(),
								import: yup.boolean(),
								edit: yup.boolean(),
								editGroup: yup.boolean(),
								simulation: yup.boolean(),
								run: yup.boolean(),
								remove: yup.boolean(),
								samples: yup.boolean(),
								schedule: yup.boolean(),
								view: yup.boolean(),
								clone: yup.boolean(),
								create: yup.boolean(),
								share: yup.boolean(),
								shareGroup: yup.boolean(),
								deleteGroup: yup.boolean()
							})
						}),
						rolesTab: yup.object({
							rolesSection: yup.object({
								clone: yup.boolean(),
								create: yup.boolean(),
								view: yup.boolean(),
								edit: yup.boolean(),
								remove: yup.boolean()
							})
						}),
						devicesTab: yup.object({
							devicesSection: yup.object({
								add: yup.boolean(),
								view: yup.boolean(),
								createGroup: yup.boolean(),
								edit: yup.boolean(),
								editGroup: yup.boolean(),
								activate: yup.boolean(),
								deleteGroup: yup.boolean(),
								remove: yup.boolean()
							}),
							inputNodesSection: yup.object({
								view: yup.boolean(),
								viewAll: yup.boolean(),
								assign: yup.boolean(),
								assignAll: yup.boolean()
							})
						}),
						usersTab: yup.object({
							usersSection: yup.object({
								resetPassword: yup.boolean(),
								view: yup.boolean(),
								createGroup: yup.boolean(),
								assignGroup: yup.boolean(),
								edit: yup.boolean(),
								editGroup: yup.boolean(),
								transferOwnership: yup.boolean(),
								assignUser: yup.boolean(),
								block: yup.boolean(),
								invite: yup.boolean(),
								deleteGroup: yup.boolean(),
								remove: yup.boolean()
							})
						}),
						jobsTab: yup.object({
							tasksSection: yup.object({
								view: yup.boolean()
							}),
							namingSection: yup.object({
								view: yup.boolean()
							}),
							trackerSection: yup.object({
								track: yup.boolean(),
								view: yup.boolean()
							}),
							jobQueueSection: yup.object({
								activate: yup.boolean(),
								create: yup.boolean(),
								view: yup.boolean(),
								edit: yup.boolean(),
								remove: yup.boolean()
							})
						}),
						settingsTab: yup.object({
							settingsSection: yup.object({
								edit: yup.boolean(),
								view: yup.boolean()
							}),
							profileSection: yup.object({
								outOfOffice: yup.boolean()
							}),
							systemSection: yup.object({
								manageNotifications: yup.boolean()
							})
						}),
						appsTab: yup.object({
							externalSection: yup.object({
								changeOwner: yup.boolean(),
								view: yup.boolean(),
								import: yup.boolean(),
								edit: yup.boolean(),
								activate: yup.boolean(),
								create: yup.boolean(),
								share: yup.boolean(),
								delete: yup.boolean()
							}),
							internalSection: yup.object({
								changeOwner: yup.boolean(),
								view: yup.boolean(),
								import: yup.boolean(),
								edit: yup.boolean(),
								activate: yup.boolean(),
								create: yup.boolean(),
								share: yup.boolean(),
								delete: yup.boolean()
							}),
							urlSection: yup.object({
								changeOwner: yup.boolean(),
								view: yup.boolean(),
								import: yup.boolean(),
								edit: yup.boolean(),
								activate: yup.boolean(),
								create: yup.boolean(),
								share: yup.boolean(),
								delete: yup.boolean()
							})
						}),
						dataTab: yup.object({
							logsSection: yup.object({
								view: yup.boolean()
							}),
							reportsSection: yup.object({
								view: yup.boolean()
							})
						}),
						formsTab: yup.object({
							formsSection: yup.object({
								add: yup.boolean(),
								view: yup.boolean(),
								createGroup: yup.boolean(),
								import: yup.boolean(),
								edit: yup.boolean(),
								editGroup: yup.boolean(),
								activate: yup.boolean(),
								clone: yup.boolean(),
								deleteGroup: yup.boolean(),
								remove: yup.boolean(),
								deactivate: yup.boolean()
							})
						})
					})
				})
				.isValidSync(obj[key])
		) {
			bool = false;
		}
	});
	return bool;
};

export const adminInfoSchema = (obj: any) =>
	yup
		.object({
			primary: yup.object({
				email: yup.string(),
				fullName: yup.string(),
				address: yup.string(),
				apt: yup.string(),
				country: yup.string(),
				state: yup.string(),
				zip: yup.string(),
				phone: yup.string(),
				city: yup.string(),
				notes: yup.string()
			}),
			secondary: yup.object({
				email: yup.string(),
				fullName: yup.string(),
				address: yup.string(),
				apt: yup.string(),
				country: yup.string(),
				state: yup.string(),
				zip: yup.string(),
				phone: yup.string(),
				city: yup.string(),
				notes: yup.string()
			})
		})
		.isValidSync(obj);

export const workflowsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				name: yup.string(),
				modified: yup.string(),
				status: yup.string(),
				acl: yup.object({
					users: yup.array(
						yup.object({
							name: yup.string(),
							role: yup.string()
						})
					)
				}),
				options: yup.object({
					TotalPageCount: yup.string(),
					Group: yup.string(),
					Valid: yup.string(),
					PreviewPageCount: yup.string()
				})
			})
		)
		.isValidSync(obj);

export const workflowGroupsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				name: yup.string(),
				created: yup.string(),
				modified: yup.string(),
				acl: yup.object({
					users: yup.array(
						yup.object({
							name: yup.string(),
							role: yup.string()
						})
					)
				})
			})
		)
		.isValidSync(obj);

export const sampleWorkflowsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				name: yup.string(),
				description: yup.string(),
				modified: yup.string(),
				status: yup.string()
			})
		)
		.isValidSync(obj);

export const formsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				title: yup.string(),
				creator: yup.string(),
				editor: yup.string(),
				valid: yup.boolean(),
				advanced: yup.boolean(),
				created: yup.number(),
				updated: yup.number(),
				formStatus: yup.string(),
				formGroup: yup.object({
					id: yup.string()
				})
			})
		)
		.isValidSync(obj);

export const formGroupsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				title: yup.string(),
				sortOrder: yup.number()
			})
		)
		.isValidSync(obj);

export const entitlementsSchema = (obj: any) =>
	yup
		.object({
			permissions: yup.object({
				workflowsTab: yup.object({
					workflowsSection: yup.object({
						changeOwner: yup.boolean(),
						createGroup: yup.boolean(),
						import: yup.boolean(),
						edit: yup.boolean(),
						editGroup: yup.boolean(),
						simulation: yup.boolean(),
						run: yup.boolean(),
						remove: yup.boolean(),
						samples: yup.boolean(),
						schedule: yup.boolean(),
						view: yup.boolean(),
						clone: yup.boolean(),
						create: yup.boolean(),
						share: yup.boolean(),
						shareGroup: yup.boolean(),
						deleteGroup: yup.boolean()
					})
				}),
				rolesTab: yup.object({
					rolesSection: yup.object({
						clone: yup.boolean(),
						create: yup.boolean(),
						view: yup.boolean(),
						edit: yup.boolean(),
						remove: yup.boolean()
					})
				}),
				devicesTab: yup.object({
					devicesSection: yup.object({
						add: yup.boolean(),
						view: yup.boolean(),
						createGroup: yup.boolean(),
						edit: yup.boolean(),
						editGroup: yup.boolean(),
						activate: yup.boolean(),
						deleteGroup: yup.boolean(),
						remove: yup.boolean()
					}),
					inputNodesSection: yup.object({
						view: yup.boolean(),
						viewAll: yup.boolean(),
						assign: yup.boolean(),
						assignAll: yup.boolean()
					})
				}),
				usersTab: yup.object({
					usersSection: yup.object({
						resetPassword: yup.boolean(),
						view: yup.boolean(),
						createGroup: yup.boolean(),
						assignGroup: yup.boolean(),
						edit: yup.boolean(),
						editGroup: yup.boolean(),
						transferOwnership: yup.boolean(),
						block: yup.boolean(),
						assignUser: yup.boolean(),
						invite: yup.boolean(),
						deleteGroup: yup.boolean(),
						remove: yup.boolean()
					})
				}),
				jobsTab: yup.object({
					tasksSection: yup.object({
						view: yup.boolean()
					}),
					namingSection: yup.object({
						view: yup.boolean()
					}),
					trackerSection: yup.object({
						track: yup.boolean(),
						view: yup.boolean()
					}),
					jobQueueSection: yup.object({
						activate: yup.boolean(),
						create: yup.boolean(),
						view: yup.boolean(),
						edit: yup.boolean(),
						remove: yup.boolean()
					})
				}),
				settingsTab: yup.object({
					settingsSection: yup.object({
						edit: yup.boolean(),
						view: yup.boolean()
					}),
					profileSection: yup.object({
						outOfOffice: yup.boolean()
					}),
					systemSection: yup.object({
						manageNotifications: yup.boolean()
					})
				}),
				appsTab: yup.object({
					externalSection: yup.object({
						changeOwner: yup.boolean(),
						view: yup.boolean(),
						import: yup.boolean(),
						edit: yup.boolean(),
						activate: yup.boolean(),
						create: yup.boolean(),
						share: yup.boolean(),
						delete: yup.boolean()
					}),
					internalSection: yup.object({
						changeOwner: yup.boolean(),
						view: yup.boolean(),
						import: yup.boolean(),
						edit: yup.boolean(),
						activate: yup.boolean(),
						create: yup.boolean(),
						share: yup.boolean(),
						delete: yup.boolean()
					}),
					urlSection: yup.object({
						changeOwner: yup.boolean(),
						view: yup.boolean(),
						import: yup.boolean(),
						edit: yup.boolean(),
						activate: yup.boolean(),
						create: yup.boolean(),
						share: yup.boolean(),
						delete: yup.boolean()
					})
				}),
				dataTab: yup.object({
					logsSection: yup.object({
						view: yup.boolean()
					}),
					reportsSection: yup.object({
						view: yup.boolean()
					})
				}),
				formsTab: yup.object({
					formsSection: yup.object({
						add: yup.boolean(),
						view: yup.boolean(),
						createGroup: yup.boolean(),
						import: yup.boolean(),
						edit: yup.boolean(),
						editGroup: yup.boolean(),
						activate: yup.boolean(),
						clone: yup.boolean(),
						deleteGroup: yup.boolean(),
						remove: yup.boolean(),
						deactivate: yup.boolean()
					})
				})
			}),
			features: yup.object({
				advanced_forms: yup.boolean(),
				advanced_roles_and_permissions: yup.boolean(),
				annotate: yup.boolean(),
				assign_tenant_manager: yup.boolean(),
				azure_ad_integration: yup.boolean(),
				convert_to_pdf_w_tesseract: yup.boolean(),
				device_sharing: yup.boolean(),
				dropbox_in: yup.boolean(),
				email_in: yup.boolean(),
				forms_management: yup.boolean(),
				forms_selector: yup.boolean(),
				home_page: yup.boolean(),
				import_device: yup.boolean(),
				license_calculator: yup.boolean(),
				licensing_platform: yup.boolean(),
				linear_forms: yup.boolean(),
				metadata_scripting: yup.boolean(),
				'multi-tiered_tenant_management': yup.boolean(),
				nfr_portal: yup.boolean(),
				odbc: yup.boolean(),
				people_node: yup.boolean(),
				processing_node_logging: yup.boolean(),
				public_user_access: yup.boolean(),
				rendering_custom_forms_on_mfps: yup.boolean(),
				'reskin_portal&customization_option': yup.boolean(),
				samples_shared_with_all_admins: yup.boolean(),
				support_for_non_mfps: yup.boolean(),
				support_for_paragon_integration: yup.boolean(),
				tenant_settings_for_timezone: yup.boolean(),
				upland_fax_connector: yup.boolean()
			})
		})
		.isValidSync(obj);

export const appsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				type: yup.string(),
				name: yup.string(),
				description: yup.string(),
				workflowId: yup.string(),
				nodeId: yup.string(),
				url: yup.string(),
				licensed: yup.boolean(),
				groupList: yup.array(yup.string()),
				acl: yup.object({
					groups: yup.array(yup.string()),
					users: yup.array(
						yup.object({
							name: yup.string(),
							role: yup.string()
						})
					)
				}),
				aclRole: yup.string()
			})
		)
		.isValidSync(obj);

export const appGroupsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				name: yup.string()
			})
		)
		.isValidSync(obj);

export const jobNamesSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				name: yup.string(),
				components: yup.array(
					yup.object({
						value: yup.string(),
						component: yup.string(),
						id: yup.string()
					})
				),
				modified: yup.number(),
				created: yup.number(),
				editor: yup.string(),
				creator: yup.string(),
				groupList: yup.array(yup.string()),
				nodes: yup.array(yup.string())
			})
		)
		.isValidSync(obj);

export const jobNameGroupsSchema = (obj: any) =>
	yup
		.array(
			yup.object({
				id: yup.string(),
				title: yup.string()
			})
		)
		.isValidSync(obj);
