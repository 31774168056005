/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId, getJobNames, getJobNameGroups } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { JobName, JobNameGroup } from 'app/store/types';
import { UNGROUPED_NAMING_GROUP_ID } from 'app/utils/default-groups';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_JOB_NAME_GROUP_ORDER_SUCCESS = 'GET_JOB_NAME_GROUP_ORDER_SUCCESS';
export const UPDATE_JOB_NAME_GROUP_ORDER_SUCCESS = 'UPDATE_JOB_NAME_GROUP_ORDER_SUCCESS';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = ['job-name-template-group-delete-failed'];

export const editJobNameGroup = ({ id, name }: { id: JobNameGroup['id']; name: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		id,
		title: name
	};

	try {
		const response = await axios.post(`/api/naming/jntg/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		console.log(errors);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job name groups'], () => {
				if (err.length > 0) {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (response.data.status === 'failed') {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('templategroup:name:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'templategroup:name:fail'));
	}
};

export const createJobNameGroup = ({ name }: { name: string }): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		title: name
	};

	try {
		const response = await axios.post(`/api/naming/jntg/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		console.log(errors);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job name groups'], () => {
				if (err.length > 0) {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (response.data.status === 'failed') {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('templategroup:name:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('templategroup:name:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'templategroup:name:fail'));
	}
};

export const deleteJobNameGroup = (id: JobNameGroup['id']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.delete(`/api/naming/jntg/${licenseGroupId}/${id}`, {
			params: {
				groupId: id
			}
		});

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job name groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.includes('job-name-template-group-delete-failed')) {
						// change color
						dispatch(appActions.alert('templategroup:delete:fail', 'warning'));
						// change error code
					} else if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('templategroup:delete:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('templategroup:delete:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('templategroup:delete:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'templategroup:delete:fail'));
	}
};

export const getJobNameGroupOrder = (successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/jnt`);

		dispatch({
			type: GET_JOB_NAME_GROUP_ORDER_SUCCESS,
			payload: {
				order: groupOrder,
				licenseGroupId
			}
		});
		if (successFn) successFn();
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateJobNameGroupOrder = (ids: string[]): AppThunk => async (dispatch, getState) => {
	// const licenseGroupId = getSelectedLicenseGroupId(getState());
	// const jobNameGroups = getJobNameGroups(getState());
	// const newOrder: any = [];

	// const data = {
	// 	ids
	// };

	// jobNameGroups.forEach(jobNameGroup => {
	// 	newOrder.push({ id: jobNameGroup.id, title: jobNameGroup.title, groupOrder: ids.indexOf(jobNameGroup.id) });
	// });

	// try {
	// 	const response = await axios.post(`/api/naming/jntg/${licenseGroupId}`, newOrder);

	// 	const err: any = findErrors(response);
	// 	console.log(responseErrors(response).length);
	// 	console.log(err);
	// 	console.log(errors);
	// 	dispatch(
	// 		licenseGroupsActions.getSelectedLicenseGroupData(['job name groups'], () => {
	// 			if (err.length > 0) {
	// 				dispatch(appActions.alert('templategroup:order:fail', 'warning'));
	// 			} else if (response.data.status === 'failed') {
	// 				dispatch(appActions.alert('templategroup:order:fail', 'warning'));
	// 			} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
	// 				dispatch(appActions.alert('templategroup:order:fail', 'warning'));
	// 			} else if (err.length === 0) {
	// 				dispatch(appActions.alert('templategroup:order:success', 'success'));
	// 			}
	// 		})
	// 	);
	// } catch (error) {
	// 	dispatch(appActions.handleError(error, 'templategroup:order:fail'));
	// }
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'jnt',
		order: ids
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};
