import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { DeviceGroup } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_DEVICE_GROUP_ORDER_SUCCESS = 'GET_DEVICE_GROUP_ORDER_SUCCESS';
export const UPDATE_DEVICE_GROUP_ORDER_SUCCESS = 'UPDATE_DEVICE_GROUP_ORDER_SUCCESS';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'no-associated-device',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-device-authorization',
	'failed-to-get-devices',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-device-group-failed',
	'device-group-delete-failed'
];

export const createDeviceGroup = ({ name }: { name: string }): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		name
	};

	try {
		const response = await axios.post(`/api/device/${licenseGroupId}/device-group`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['device groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('device group name already exists')) {
						// change color
						dispatch(appActions.alert('devicegroup:create:fail', 'warning'));
						// change error code
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('devicegroup:create:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('devicegroup:create:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'devicegroup:create:fail'));
	}
};

export const editDeviceGroup = ({ id, name }: { id: DeviceGroup['id']; name: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		id,
		name
	};

	try {
		const response = await axios.post(`/api/device/${licenseGroupId}/device-group`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		console.log(errors);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['device groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('device group name already exists')) {
						// change color
						dispatch(appActions.alert('devicegroup:name:fail', 'warning'));
						// change error code
					} else if (err.contains('device group no longer exists')) {
						dispatch(appActions.alert('devicegroup:name:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('devicegroup:name:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('devicegroup:name:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'devicegroup:name:fail'));
	}
};

export const deleteDeviceGroup = (deviceGroupId: DeviceGroup['id']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.delete(`/api/device/${licenseGroupId}/device-group/${deviceGroupId}`);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices', 'device groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('device group no longer exists')) {
						// change color
						dispatch(appActions.alert('devicegroup:name:fail', 'warning'));
						// change error code
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('devicegroup:delete:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('devicegroup:delete:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'devicegroup:delete:fail'));
	}
};

export const getDeviceGroupOrder = (successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/device`);

		dispatch({
			type: GET_DEVICE_GROUP_ORDER_SUCCESS,
			payload: {
				order: groupOrder,
				licenseGroupId
			}
		});
		if (successFn) successFn();
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateDeviceGroupOrder = (groupIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'device',
		order: groupIds
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};
