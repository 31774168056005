/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { arrayify, getWfxUrl, responseError, responseErrors } from 'app/utils/helpers';
import { getProfile, getSelectedLicenseGroupId, getWorkflows } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Role, User, UserGroup } from 'app/store/types';
import _ from 'lodash';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_USER_GROUP_ORDER_SUCCESS = 'GET_USER_GROUP_ORDER_SUCCESS';
export const UPDATE_USER_GROUP_ORDER_SUCCESS = 'UPDATE_USER_GROUP_ORDER_SUCCESS';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

export const createUserGroup = ({
	name,
	user = '',
	role = ''
}: {
	name: string;
	user?: User['id'];
	role?: Role['id'];
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		...(!_.isEmpty(user) && { user }),
		...(!_.isEmpty(role) && { role })
	};

	try {
		const response = await axios.post(`/api/user/${licenseGroupId}/user-group`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to create user group', 'warning'));
		} else {
			dispatch(appActions.alert('user group created', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['user groups']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:create group'));
	}
};

export const editUserGroup = ({ id, name }: { id: UserGroup['id']; name: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		id
	};

	try {
		const response = await axios.post(`/api/user/${licenseGroupId}/user-group`, data);

		// // @ts-ignore
		// if (responseError(response)) {
		// 	dispatch(appActions.alert('failed to update user group', 'warning'));
		// } else {
		// 	dispatch(appActions.alert('User group updated', 'success'));
		// }
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(['user groups'], () => {
					// @ts-ignore
					if (responseError(response)) {
						dispatch(appActions.alert('failed to update user group', 'warning'));
					} else {
						dispatch(appActions.alert('User group updated', 'success'));
					}
				})
			);
			// FIXME: change to actual back end error once it exists
		} else if (err.includes('same-name')) {
			dispatch(appActions.alert('user group same name', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:rename group'));
	}
};

// CHANGEME::once finalized this logic should likely be moved to the backend (it doesn't even get the latest workflows data)
const removeUserGroupsFromWorkflows = (userGroupIds: UserGroup['id'][]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const workflows = getWorkflows(getState());

	const modifiedWorkflowAcls = workflows
		// get list of only relevant workflows
		.filter(workflow => workflow.acl.groups.some(({ name }) => userGroupIds.includes(name)))
		// get list of acls with...
		.map(({ id, acl }) => ({
			id,
			acl: {
				...acl,
				groups: acl.groups
					// ...removed groups deleted
					.filter(({ name }) => !userGroupIds.includes(name))
			}
		}));

	const responses = await Promise.all(
		modifiedWorkflowAcls.map(({ id, acl }) => axios.post(`${wfxApiUrl}/api/wfx/${id}/acl`, acl))
	);

	if (responseErrors(responses).length) {
		throw new Error();
	}
};

export const deleteUserGroup = (userGroupId: UserGroup['id']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		await dispatch(removeUserGroupsFromWorkflows([userGroupId]));
		const response = await axios.delete(`/api/user/${licenseGroupId}/user-group/${userGroupId}`);
		const err: any = findErrors(response);
		// if (err.length === 0) {
		// 	// @ts-ignore
		// 	if (responseError(response)) {
		// 		dispatch(appActions.alert('failed to delete user group', 'warning'));
		// 	} else {
		// 		dispatch(appActions.alert('User group deleted', 'success'));
		// 	}
		// } else {
		// 	if (err.includes('user-group-delete-failed')) {
		// 		dispatch(appActions.alert('failed to delete user group', 'warning'));
		// 	}
		// 	if (err.includes('unexpected-error')) {
		// 		dispatch(appActions.alert('failed to delete user group', 'warning'));
		// 	}
		// }
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['users', 'user groups'], () => {
				if (err.length === 0) {
					// @ts-ignore
					if (responseError(response)) {
						dispatch(appActions.alert('failed to delete user group', 'warning'));
					} else {
						dispatch(appActions.alert('user group deleted', 'success'));
					}
				} else {
					if (err.includes('user-group-delete-failed')) {
						dispatch(appActions.alert('failed to delete user group', 'warning'));
					}
					if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('failed to delete user group', 'warning'));
					}
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:delete group'));
	}
};

export const getUserGroupOrder = (successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/user`);

		dispatch({
			type: GET_USER_GROUP_ORDER_SUCCESS,
			payload: {
				order: groupOrder,
				licenseGroupId
			}
		});
		if (successFn) successFn();
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateUserGroupOrder = (groupIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'user',
		order: groupIds
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateUserGroupConfig = (id: string, user: string, role: string, option: string): AppThunk => async (
	dispatch,
	getState
) => {
	console.log(role);
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		id,
		...(!_.isEmpty(user) && { user }),
		...(!_.isEmpty(role) ? { role } : { option })
	};
	console.log(data);

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/user-group-config`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['user groups'], () => {
				if (err.length === 0) {
					// @ts-ignore
					if (responseError(response)) {
						dispatch(appActions.alert('user group config:edit:fail', 'warning'));
					} else {
						dispatch(appActions.alert('user group config:edit:success', 'success'));
					}
				} else {
					if (err.includes('update-user-group-failed')) {
						dispatch(appActions.alert('user group config:edit:fail', 'warning'));
					}
					if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('user group config:edit:fail', 'warning'));
					}
				}
			})
		);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};
